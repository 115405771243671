import React from 'react';

export type TMessenger = {
    id: string;
    component: React.ReactNode;
    href: string;
}

export type TSetState<T> = React.Dispatch<React.SetStateAction<T>>;

export enum E_MAIN_PAGE_SWITCHER_ITEMS {
    ABOUT, SCREENSHOTS, WORLD, CHARACTERS
}
