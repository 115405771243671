import React, { useState } from "react";
import { MainContentView } from "./main-content.view";
import { E_MAIN_PAGE_SWITCHER_ITEMS } from "shared";
import { useTranslate } from "shared/translation/utils";

export const MainContent: React.FC = () => {
  const [currentItem, setCurrentItem] = useState<E_MAIN_PAGE_SWITCHER_ITEMS>(
    E_MAIN_PAGE_SWITCHER_ITEMS.ABOUT
  );
  const t = useTranslate();

  return (
    <MainContentView
      currentItem={currentItem}
      changeItem={setCurrentItem}
      aboutText={t("About")}
      worldText={t("World")}
    />
  );
};
