import {CHARACTERS_IMAGES} from 'shared/assets/characters';
import {TCharacterObject} from './main-characters.types';

export const charactersArray: TCharacterObject[] = [
  {
    image: CHARACTERS_IMAGES.susano,
    text: 'Susano'
  },
  {
    image: CHARACTERS_IMAGES.amaterasu,
    text: 'Amaterasu'
  },
  {
    image: CHARACTERS_IMAGES.tsukuomi,
    text: 'Tsukuemi'
  }
];

