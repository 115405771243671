import React from "react";
import { E_MAIN_PAGE_SWITCHER_ITEMS, TSetState } from "shared";
import s from "./main-page-switcher.module.css";
import { TSwitcherItem } from "./main-page-switcher.types";
import { useTranslate } from "shared/translation/utils";

type Props = {
  currentItem: E_MAIN_PAGE_SWITCHER_ITEMS;
  changeItem: TSetState<E_MAIN_PAGE_SWITCHER_ITEMS>;
  items: TSwitcherItem[];
};

/**
 * @return {React.FC<Props>}
 **/
export const MainPageSwitcherView: React.FC<Props> = ({
  currentItem,
  changeItem,
  items,
}: Props) => {
  const t = useTranslate();
  const itemsJsx = items.map((item: TSwitcherItem) => {
    return (
      <div
        key={item.id}
        className={`${s.item}
       ${currentItem === item.id ? s.selected : ""}`}
        onClick={() => changeItem(item.id)}
      >
        {t(`${item.text}`)}
      </div>
    );
  });

  return <nav className={s.wrapper}>{itemsJsx}</nav>;
};
