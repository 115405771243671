import React from 'react';
import s from './main-about.module.css';

type Props = {
  text: string;
}
export const MainAboutView: React.FC<Props> = ({text}: Props) => {
  return (
    <div className={s.wrapper}>
      <p>
        {text}
      </p>
    </div>
  );
};
