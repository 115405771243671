import React from 'react';
import {AboutUsContainer, Footer} from 'widgets';

export const AboutUsView: React.FC = () => {
    return (
        <>
            <AboutUsContainer/>
            <Footer/>
        </>
    );
};
