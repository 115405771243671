import { HeaderContainerView } from "./header-container.view";
import { useResizeHeader } from "./header-container.hooks";
import React from "react";
import { useTranslate } from "shared/translation/utils";

export const HeaderContainer: React.FC = () => {
  const isMobile = useResizeHeader();
  const t = useTranslate();

  return <HeaderContainerView isMobile={isMobile} aboutUsText={t("AboutUS")} />;
};
