import React from "react";
import s from "./main-characters.module.css";
import { TCharacterObject } from "./main-characters.types";
import { useTranslate } from "shared/translation/utils";

type Props = {
  charactersArray: TCharacterObject[];
};

/**
 * @return {React.FC<Props>}
 **/
export const MainCharactersView: React.FC<Props> = ({
  charactersArray,
}: Props) => {
  const t = useTranslate();

  const charactersJsx = charactersArray.map((character: TCharacterObject) => {
    return (
      <div key={character.image} className={s.characterWrapper}>
        <img src={character.image} alt="character" />
        <p>{t(character.text)}</p>
      </div>
    );
  });

  return <div className={s.wrapper}>{charactersJsx}</div>;
};
