import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import image from "../../../shared/particles/blossom.png"
import { useCallback } from "react";

export const ParticlesView = () =>
{
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadSlim(engine);
      }, []);
    
      const particlesLoaded = useCallback(async (container: Container | undefined) => {
      }, []);

    return (
        <div className="particles-container">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              particles: {
                number: {
                  value: 200, // Number of particles
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                shape: {
                  type: "image", // Use image shape
                  image: [
                    {
                      src: image, // Replace with your image path
                      width: 100, // Width of the image
                      height: 100, // Height of the image
                    },
                  ],
                },
                rotate: {
                    random: true, // Randomly rotate the particles
                    value: 360, // Full rotation
                    direction: "clockwise", // Can be 'clockwise' or 'counter-clockwise'
                    animation: true, // Enable rotation animation
                    speed: 10, // Speed of rotation
                  },
                size: {
                  value: 10, // Size of the particles
                  random: true,
                },
                move: {
                  enable: true,
                  speed: 2, // Speed of falling
                  direction: "bottom-right", // Falling direction
                  random: false,
                  straight: false,
                  out_mode: "out",
                },
                attract: {
                    enable: true,
                  },
                opacity: {
                  value: 0.9, // Opacity of particles
                  random: true,
                },
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true, // Enable hover events
                    mode: "repulse", // Mode set to repulse
                  },
                  onclick: {
                    enable: false,
                  },
                  resize: true,
                },
                modes: {
                  repulse: {
                    distance: 80, // Distance to repel particles from the cursor
                    duration: 1, // Duration of the repulsion effect
                  }
                }
              },
              retina_detect: true,
            }}
          />
      </div>
    );
};