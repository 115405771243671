import { E_ROUTES, Logo } from "shared";
import s from "./header-desktop.module.css";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { ChangeLanguage } from "features/change-language";

type Props = {
  messengers: React.ReactNode;
  aboutUsText: string;
};

export const HeaderDesktopView: React.FC<Props> = ({
  messengers,
  aboutUsText,
}: Props) => {
  const isActiveNavFn = ({
    isActive,
  }: {
    isActive: boolean;
  }): string | undefined => (isActive ? s.active : "");

  return (
    <>
      <div className={s.navWrapper}>
        <Link to={E_ROUTES.MAIN}>
          <Logo />
        </Link>
        <nav>
          <NavLink to={E_ROUTES.MAIN} className={isActiveNavFn}>
            <span>The Last Eclipse</span>
          </NavLink>
          <NavLink to={E_ROUTES.ABOUT_US} className={isActiveNavFn}>
            <span>{aboutUsText}</span>
          </NavLink>
        </nav>
      </div>
      <nav className={s.rightBlock}>
        <ChangeLanguage />
        <div className={s.messengersWrapper}>{messengers}</div>
      </nav>
    </>
  );
};
