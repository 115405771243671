import React from "react";
import s from "./main-screenshots.module.css";
import { Image } from "antd";

type Props = {
  images: string[];
};

/**
 * @return {React.FC<Props>}
 **/
export const MainScreenshotsView: React.FC<Props> = ({ images }: Props) => {
  const imagesJsx = images.map((image: string) => (
    <Image
      src={image}
      alt={image}
      key={image}
      wrapperClassName={s.image}
      preview={{ mask: <div /> }}
      loading="lazy"
    />
  ));

  return <div className={s.wrapper}>{imagesJsx}</div>;
};
