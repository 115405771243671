import { useState } from "react";
import { MainScreenView } from "./main-screen.view";

export const MainScreen = () => {
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

  return (
    <MainScreenView isVideoOpen={isVideoOpen} setIsVideoOpen={setIsVideoOpen} />
  );
};
