import React from "react";
import { AboutUsContainerView } from "./about-us-container.view";
import { useTranslate } from "shared/translation/utils";

export const AboutUsContainer: React.FC = () => {
  const t = useTranslate();

  return (
    <AboutUsContainerView
      companyDescription={t("companyDescription")}
      bossInfo={t("bossInfo")}
      contactsInfo={t("contactsInfo")}
      investorInfo={t("investorInfo")}
    />
  );
};
