import {messengersArray, TMessenger} from 'shared';
import s from './header-container.module.css';
import React from 'react';
import {HeaderDesktop} from '../header-desktop';
import {HeaderMobile} from '../header-mobile';

type Props = {
    isMobile: boolean;
    aboutUsText: string
}

export const HeaderContainerView: React.FC<Props> = ({isMobile, aboutUsText}: Props) => {
    const messengers = messengersArray().map((messengerObj: TMessenger): React.ReactNode => {
        return (
            <a href={messengerObj.href} key={messengerObj.id}>
                {messengerObj.component}
            </a>
        );
    });

    let content;
    if (!isMobile) {
        content = <HeaderDesktop aboutUsText={aboutUsText}>
            {messengers}
        </HeaderDesktop>;
    } else {
        content = <HeaderMobile aboutUsText={aboutUsText}>{messengers}</HeaderMobile>;
    }

    return (
        <header className={s.wrapper}>
            {content}
        </header>
    );
};
