import {Footer, MainContent, MainScreen} from 'widgets';
import React from 'react';

export const MainPageView: React.FC = () => {
    return (
        <>
            <MainScreen/>
            <MainContent/>
            <Footer/>
        </>
    );
};
