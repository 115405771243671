import React from 'react';
import {MainPageSwitcherView} from './main-page-switcher.view';
import {SWITCHER_ITEMS_ARRAY} from './main-page-switcher.constants';
import {E_MAIN_PAGE_SWITCHER_ITEMS, TSetState} from 'shared';

type Props = {
  currentItem: E_MAIN_PAGE_SWITCHER_ITEMS;
  changeItem: TSetState<E_MAIN_PAGE_SWITCHER_ITEMS>;
};

/**
 * @return {React.FC<Props>}
 **/
export const MainPageSwitcher: React.FC<Props> = ({currentItem, changeItem}: Props) => {
  return (
    <MainPageSwitcherView
      currentItem={currentItem}
      changeItem={changeItem}
      items={SWITCHER_ITEMS_ARRAY}
    />
  );
};
