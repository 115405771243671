import {useEffect, useState} from 'react';

export const useResizeHeader = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const resizeFn = () => {
      if (window.innerWidth > 850) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    resizeFn();

    window.addEventListener('resize', () => {
      resizeFn();
    });
  }, []);

  return isMobile;
}
