import {createBrowserRouter} from 'react-router-dom';
import {AboutUs, MainPage} from '../pages';
import {E_ROUTES} from '../shared';
import {Layout} from '../widgets/layout';

export const router = createBrowserRouter([
    {
        path: E_ROUTES.MAIN,
        element: <Layout><MainPage/></Layout>,
    },
    {
        path: E_ROUTES.ABOUT_US,
        element: <Layout><AboutUs/></Layout>
    }
]);
