import {Telegram, Tiktok, Youtube, TMessenger, VK} from 'shared';

export const messengersArray: () => TMessenger[] = () => [
    {
        id: 'vk',
        component: <VK/>,
        href: "https://vk.com/thelasteclipsegame"
    },
    {
        id: 'telegram',
        component: <Telegram />,
        href: 'https://t.me/gdaeon'
    },
    {
        id: 'tiktok',
        component: <Tiktok />,
        href: 'https://www.tiktok.com/@gdaeon'
    },
    {
        id: 'youtube',
        component: <Youtube />,
        href: 'https://www.youtube.com/@gdaeon8542'
    }
];
