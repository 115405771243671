import {useUnit} from 'effector-react';
import {LANGUAGE_DICTIONARIES} from './index';
import {model} from './model';

const t = (translationKey: string, userLanguage : string): string => {
  const currentTranslationDict = LANGUAGE_DICTIONARIES?.[userLanguage] ?? '';

  return currentTranslationDict?.[translationKey] ?? '';
};

export const useLanguageModel = () => useUnit([model.$language, model.languageSet]);

export const useTranslate = () => {
  const language = useUnit(model.$language);

  return (translationKey: string) => t(translationKey, language);
};
