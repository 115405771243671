import React from 'react';
import s from './footer.module.css';
import {BOTTOM_TEXT} from './footer.constants';

export const Footer: React.FC = () => {
  return (
    <footer className={s.wrapper}>
      <span className={s.bottomText}>{BOTTOM_TEXT}</span>
    </footer>
  );
};
