import {ReactNode} from 'react';
import s from './styles.module.css';
import {HeaderContainer} from './ui/header';

type Props = {
    children: ReactNode;
}

export const Layout = ({children}: Props) => {
    return (
        <div className={s.wrapper}>
            <HeaderContainer/>
            {children}
        </div>
    );
};
