import { HeaderDesktopView } from "./header-desktop.view";
import React from "react";

type Props = {
  children: React.ReactNode;
  aboutUsText: string;
};

export const HeaderDesktop: React.FC<Props> = ({
  children,
  aboutUsText,
}: Props) => {
  return <HeaderDesktopView messengers={children} aboutUsText={aboutUsText} />;
};
