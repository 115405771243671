import React from 'react';
import {MainPageSwitcher} from '../main-page-switcher';
import s from './main-content.module.css';
import {E_MAIN_PAGE_SWITCHER_ITEMS, TSetState} from 'shared';
import {MainAbout} from '../main-about';
import {MainScreenshots} from '../main-screenshots';
import {MainCharacters} from '../main-characters';

type Props = {
  currentItem: E_MAIN_PAGE_SWITCHER_ITEMS;
  changeItem: TSetState<E_MAIN_PAGE_SWITCHER_ITEMS>;
  aboutText: string;
  worldText: string;
};

/**
 * @return {React.FC<Props>}
 **/
export const MainContentView: React.FC<Props> = ({
   currentItem,
   changeItem,
   aboutText,
   worldText
}: Props) => {
  let contentJsx;

  switch (currentItem) {
    case E_MAIN_PAGE_SWITCHER_ITEMS.SCREENSHOTS:
      contentJsx = <MainScreenshots/>;
      break;
    case E_MAIN_PAGE_SWITCHER_ITEMS.CHARACTERS:
      contentJsx = <MainCharacters/>;
      break;
    case E_MAIN_PAGE_SWITCHER_ITEMS.WORLD:
      contentJsx = <MainAbout text={worldText}/>;
      break;
    case E_MAIN_PAGE_SWITCHER_ITEMS.ABOUT:
    default:
      contentJsx = <MainAbout text={aboutText}/>;
      break;
  }

  return (
    <div className={s.wrapper}>
      <MainPageSwitcher currentItem={currentItem} changeItem={changeItem}/>
      <div className={s.contentWrapper}>
        {contentJsx}
      </div>
    </div>
  );
};
