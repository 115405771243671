import s from "./main-screen.module.css";
import { useState, useEffect, useRef } from "react";
import React from "react";
import videoBg from "shared/assets/video_thumbnail.png";
import gameplayVideoBg from "shared/assets/gameplay_video.png";
import { Play } from "shared";
import { Modal } from "antd";
import { useTranslate } from '../../../shared/translation/utils';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Make sure to import the carousel styles
import { Particles } from "../particles";

type Props = {
  isVideoOpen: boolean;
  setIsVideoOpen: (val: boolean) => void;
};

export const MainScreenView: React.FC<Props> = ({
  isVideoOpen,
  setIsVideoOpen,
}) => {
  const t = useTranslate();

  // State to hold the current video source and title
  const [currentVideoSrc, setCurrentVideoSrc] = useState<string>(t('videoSrc'));
  const [currentVideoTitle, setCurrentVideoTitle] = useState<string>(t('videoTitle'));
  
  // State for video data
  const [videoData, setVideoData] = useState([
    { src: t('gameplayVideoSrc'), title: t('gameplayVideoTitle'), img: gameplayVideoBg },
    { src: t('videoSrc'), title: t('videoTitle'), img: videoBg },
  ]);

  // Ref to track the current carousel index
  const currentIndexRef = useRef(0);

  // Effect to update videoData when language changes
  useEffect(() => {
    const updatedVideoData = [
      { src: t('gameplayVideoSrc'), title: t('gameplayVideoTitle'), img: gameplayVideoBg },
      { src: t('videoSrc'), title: t('videoTitle'), img: videoBg },
    ];
    setVideoData(updatedVideoData);
    
    // Update the current video based on the current index
    const currentVideo = updatedVideoData[currentIndexRef.current];
    setCurrentVideoSrc(currentVideo.src);
    setCurrentVideoTitle(currentVideo.title);
  }, [t]); // Runs whenever the translation function changes

  // Click handler for carousel items
  const handleVideoClick = (videoSrc: string, videoTitle: string) => {
    setCurrentVideoSrc(videoSrc);
    setCurrentVideoTitle(videoTitle);
    setIsVideoOpen(true);
  };

  // Handler for carousel change
  const handleCarouselChange = (index: number) => {
    currentIndexRef.current = index; // Update the ref
    const selectedVideo = videoData[index];
    setCurrentVideoSrc(selectedVideo.src);
    setCurrentVideoTitle(selectedVideo.title);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.videoWrapper}>
        <div className={s.videoBgWrapper}>
          {/* Carousel Implementation */}
          <Carousel
            showThumbs={false} // Optionally hide thumbnails
            infiniteLoop
            swipeable
            autoPlay={!isVideoOpen}
            interval={5000}
            showStatus={false}
            onChange={handleCarouselChange} // Update title on carousel change
            className={s.carousel}
          >
            {/* Add your images */}
            {videoData.map((video, index) => (
              <div key={index} onClick={() => handleVideoClick(video.src, video.title)}>
                <img src={video.img} alt={video.title} />
                <div className={s.svgWrapper}>
                  <Play />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <h1 className={s.title}>{currentVideoTitle}</h1> {/* Will change based on carousel */}
      </div>

      <Particles/>

      <Modal
        open={isVideoOpen}
        onCancel={() => setIsVideoOpen(false)}
        centered
        footer={<></>}
        className={s.modal}
        width={900}
        closeIcon={<></>}
        destroyOnClose
      >
        <iframe
          src={currentVideoSrc}
          title={currentVideoTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          className={s.iframe}
        />
      </Modal>
    </div>
  );
};
