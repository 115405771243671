import s from "./change-language.module.css";
import { useLanguageModel } from "shared/translation/utils";
import { Tabs } from "antd";

export const ChangeLanguage = () => {
  const [language, changeLanguage] = useLanguageModel();

  return (
    <Tabs
      items={[
        {
          label: "Русский",
          key: "RU",
        },
        {
          label: "English",
          key: "EN",
        },
      ]}
      className={s.language}
      activeKey={language}
      onChange={changeLanguage}
    />
  );
};
