import { createStore, createEvent, sample } from "effector";

const getLanguage = () => {
    const lang = navigator?.language;
  
    if (lang.indexOf('ru') !== -1) {
      return 'RU';
    } else {
      return 'EN';
    }
  };
  

const languageSet = createEvent<string>();

const $language = createStore<string>(getLanguage());

sample({
    clock: languageSet,
    target: $language,
});

export const model = {
    $language, languageSet
};

