import { Burger, E_ROUTES } from "shared";
import s from "./header-mobile.module.css";
import { Link } from "react-router-dom";
import React from "react";
import { ChangeLanguage } from "features/change-language";

type Props = {
  active: boolean;
  clickBurgerFn: () => void;
  children: React.ReactNode;
  aboutUsText: string;
};

export const HeaderMobileView: React.FC<Props> = ({
  active,
  clickBurgerFn,
  children,
  aboutUsText,
}: Props) => {
  return (
    <div className={s.wrapper}>
      <div className={s.menuSwitcher} onClick={clickBurgerFn}>
        <Burger opened={active} />
      </div>
      <div className={`${s.mobileWrapper} ${active ? s.active : ""}`}>
        <nav>
          <Link to={E_ROUTES.MAIN}>
            <span>The Last Eclipse</span>
          </Link>
          <Link to={E_ROUTES.ABOUT_US}>
            <span>{aboutUsText}</span>
          </Link>
        </nav>
        <div className={s.bottomBlock}>
          <ChangeLanguage />
          <div className={s.messengersWrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};
