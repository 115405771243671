import React from 'react';
import {MainAboutView} from './main-about.view';

type Props = {
  text: string;
}

export const MainAbout: React.FC<Props> = ({text}) => {
  return (
    <MainAboutView text={text}/>
  );
};
